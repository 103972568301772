<template lang="pug">
  .vx-card__title.mb-8
    change-language(class="float-right")
    h4.mb-4 {{ $t('auth.recovery') }}
    errors-list(
      v-if="this.errorsLogin != null"
      :errorList="this.errorsLogin"
    )
    form.pt-3
      vs-input.w-full.no-icon-border(
        ref="password"
        name="password"
        v-validate="'required|min:3'"
        :data-vv-as="$t('auth.password')"
        icon="icon icon-lock"
        icon-pack="feather"
        type="password"
        :label-placeholder="$t('auth.password')"
        v-model="reset_password.new_password1"
        autocomplete="off"
      )
      span.text-danger.text-sm(
        v-show="errors.has('password')"
      ) {{ errors.first('password') }}
      vs-input.w-full.no-icon-border(
        name="password_confirmation"
        v-validate="'required|confirmed:password'"
        :data-vv-as="$t('auth.confirm_password')"
        icon="icon icon-lock"
        icon-pack="feather"
        type="password"
        :label-placeholder="$t('auth.confirm_password')"
        v-model="reset_password.new_password2"
        autocomplete="off"
      )
      span.text-danger.text-sm(
        v-show="errors.has('password_confirmation')"
      ) {{ errors.first('password_confirmation') }}
      .flex.flex-wrap.justify-center.my-5
        vs-button.float-right(
          @click.prevent="Recovery"
        ) {{ $t('auth.recovery') }}
</template>

<script>

import ChangeLanguage from "@/components/lang/ChangeLanguage"
import HTTP from "@/http-common.js"
import ErrorsList from "@/components/errors/Errors"
import { mapState } from "vuex"

// Constantes
const COMPONENT_NAME = "ResetPassword"

export default {
  name: COMPONENT_NAME,
  components: {
    "change-language": ChangeLanguage,
    "errors-list": ErrorsList
  },
  data() {
    return {
      reset_password: {
        new_password1: "",
        new_password2: "",
        token: "",
        uid: ""
      }
    }
  },
  computed: {
    ...mapState("auth", ["errorsLogin"])
  },
  methods: {
    Recovery() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.reset_password.token = this.$route.params.code
          this.reset_password.uid = this.$route.params.uid
          let change_password = this.reset_password
          HTTP.post("/rest-auth/password/reset/confirm/", change_password)
            .then(() => {
              this.$swal({
                position: "center",
                type: "success",
                title: this.$t("auth.reset-recovery"),
                allowOutsideClick: false,
                confirmButtonColor: "#FDD402",
                cancelButtonColor: "#ea5455",
                showConfirmButton: false,
                timer: 2000
              }).then(result => {
                if (result) {
                  this.$router.push({ name: "Login" })
                }
              })
            })
            .catch(({ response }) => {
              this.$store.commit("auth/SET_ERROR", response.data)
            })
        }
      })
    }
  }
}
</script>