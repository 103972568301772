<template lang="pug">
  vue-recovery-reset
</template>
<script>

import RecoveryReset from "@/components/auth/ResetPasswordRecovery"

const COMPONENT_NAME="RecoveryResetReset"

export default {
  name: COMPONENT_NAME,
  components: {
  "vue-recovery-reset": RecoveryReset
  }
}
</script>
